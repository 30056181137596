import * as Portal from '@radix-ui/react-portal'
import * as Sentry from '@sentry/react'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { SESSION_ID } from 'domains/analytics/constants'
import { IntlProvider } from 'domains/i18n/components'
import { defaultAppLocale } from 'domains/i18n/constants'
import { BrowserRouter as Router } from 'kitchen/router'
import { ExtendedResponseError } from 'kitchen/utils/error'
import { queryClient, queryCache } from 'kitchen/utils/query-client'
import { IntercomProvider } from 'react-use-intercom'
import { Tooltip, Toast } from 'salad/components'
import { FullstoryProvider } from './fullstory'

queryCache.config.onError = (error) => {
  if (error instanceof ExtendedResponseError) {
    return
  }

  Sentry.captureException(error)
}

interface ProvidersProps {
  children?: React.ReactNode
}

export const AppProviders = ({ children }: ProvidersProps) => (
  <Router>
    <FullstoryProvider>
      <QueryClientProvider client={queryClient}>
        <IntercomProvider
          appId={process.env.INTERCOM_APP_ID}
          shouldInitialize={!process.env.PLAYWRIGHT}
          autoBoot
          autoBootProps={{
            customAttributes: {
              env: process.env.BUILD_MODE,
              session_id: SESSION_ID,
            },
          }}
        >
          <IntlProvider locale={defaultAppLocale} packageName="app">
            <Toast.Provider>
              <Tooltip.Provider>{children}</Tooltip.Provider>
            </Toast.Provider>
          </IntlProvider>
        </IntercomProvider>
        {process.env.BUILD_MODE === 'development' && (
          <Portal.Root>
            <ReactQueryDevtools
              initialIsOpen={false}
              position="bottom-right"
              toggleButtonProps={{ style: { bottom: 135, right: 14 } }}
            />
          </Portal.Root>
        )}
      </QueryClientProvider>
    </FullstoryProvider>
  </Router>
)
