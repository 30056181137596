// https://github.com/react-hookz/deep-equal
export { isEqual } from '@react-hookz/deep-equal'
import type { FilledObject } from '../types'
import { isObject, nonNullable } from './helpers'

interface CriteriaFunction<Item, Key> {
  (item: Item): Key
}

// e.g. considers "a" and "å" equal
const equalsInLocale = (a: string, b: string, locale: string | undefined) =>
  new Intl.Collator(locale, { sensitivity: 'base' }).compare(a, b) === 0

export const groupBy = function <Item, Key extends PropertyKey>(
  arr: Item[],
  criteria: CriteriaFunction<Item, Key>,
  locale?: string
) {
  return arr.reduce(function (obj, item) {
    const key = criteria(item)
    // try simple key lookup
    if (Array.isArray(obj[key])) {
      obj[key].push(item)
      return obj
    }
    // try key lookup by locale equality
    const existingKey = Object.keys(obj).find((foundKey) =>
      equalsInLocale(foundKey, key.toString(), locale)
    ) as Key | undefined
    if (existingKey === undefined) {
      // If the key doesn't exist yet, create it
      obj[key] = [item]
    } else {
      obj[existingKey].push(item)
    }
    return obj
  }, {} as { [key in Key]: Item[] })
}

export const findById = <Id, Item extends Id extends undefined ? unknown : { id: Id }>(
  array: Item[] | undefined,
  id: Id
): Item | undefined =>
  array?.find((item) => isObject(item) && 'id' in item && item.id === id)

export const intersect = <Type>(a: Array<Type>, b: Array<Type>): Array<Type> =>
  a.filter(Set.prototype.has, new Set(b))

/**
 * case-insensitive sort
 */
export const alphabeticSort = (a: string, b: string, locale?: string) =>
  a.localeCompare(b, locale, { sensitivity: 'base' })

type Head<InputArray extends Array<unknown>> = InputArray extends [
  infer ArrayHead,
  ...infer _
]
  ? ArrayHead
  : InputArray[0] | undefined

/**
 * type-safe alternative to array[0]
 */
export const head = <Item>(input: Item[]): Head<Item[]> => input[0]

export function findDuplicates<Item extends string>(input: Item[]): Item[] {
  const allDuplicates = input.filter((item, index) => input.indexOf(item) !== index)
  return [...new Set(allDuplicates)]
}

export const isFile = (input: unknown): input is File =>
  'File' in window && input instanceof File

/** immutable primitive item(s) removal */
export function remove<Item extends string | number>(
  data: Array<Item>,
  itemsToRemove: Item[] | Item
): Array<Item> {
  if (Array.isArray(itemsToRemove)) {
    return data.filter((dataItem) => !itemsToRemove.includes(dataItem))
  }
  return data.filter((dataItem) => itemsToRemove !== dataItem)
}

/**
 * turn var names (snake, camel) into readable strings
 */
export function humanize(input: string): string {
  return input
    .split(/(?=[A-Z_])/)
    .join(' ')
    .replaceAll('_', '')
    .toLowerCase()
}

export function removeNullableValues<Input extends object>(object: Input) {
  return Object.fromEntries(
    Object.entries(object).filter(([_, value]) => nonNullable(value))
  ) as FilledObject<Input>
}
