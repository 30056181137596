import { FormattedMessage } from 'react-intl'
import { Surface, Button } from 'salad/components'
import { VStack, Text } from 'salad/primitives'
import { theme } from 'salad/stitches'

interface PaymentsFreeTrialBannerProps {
  onStart: () => void
}

export function PaymentsFreeTrialBanner({ onStart }: PaymentsFreeTrialBannerProps) {
  return (
    <Surface variant="snippet" style={{ borderRadius: theme.radii[8].toString() }}>
      <VStack gap={8} p={12}>
        <Text as="h4" variant="title-14" align="center">
          <FormattedMessage
            id="billing.payments-free-trial-banner.title"
            defaultMessage="Try payments for free"
          />
        </Text>
        <Button.Root variant="common" size="extra-small" onClick={() => onStart()}>
          <Button.Content>
            <FormattedMessage
              id="billing.payments-free-trial-banner.start"
              defaultMessage="Learn more"
            />
          </Button.Content>
        </Button.Root>
      </VStack>
    </Surface>
  )
}
