import { Locale } from 'kitchen/constants'
import { toDate, diffDays } from 'kitchen/utils/native-date'
import { lazy, Suspense } from 'react'
import { FormattedMessage } from 'react-intl'
import { Surface, Status, AnimatedSuccessIcon } from 'salad/components'
import { Box } from 'salad/primitives'
import type { CompanySubscription } from '../../types'
import { getPaymentsFreeTrialSubscriptionAddon } from '../../utils'

const Confetti = lazy(() =>
  import('./confetti').then((mod) => ({ default: mod.Confetti }))
)

function getDays(subscription: CompanySubscription) {
  const addon = getPaymentsFreeTrialSubscriptionAddon(subscription)

  if (addon === undefined) {
    return Locale.MAX_DAYS_IN_MONTH
  }

  const startDate = toDate(addon.startDate)
  const endDate = toDate(addon.endDate)
  return diffDays(endDate, startDate)
}

interface PaymentsFreeTrialConfirmationProps {
  subscription: CompanySubscription
  onDone: () => void
}

export function PaymentsFreeTrialConfirmation({
  subscription,
  onDone,
}: PaymentsFreeTrialConfirmationProps) {
  const days = getDays(subscription)

  return (
    <Surface p={32} style={{ maxWidth: 448, marginInline: 'auto' }}>
      <Status.Root variant="dialog">
        <Status.Icon>
          <Suspense fallback={<Box style={{ height: 64, width: 64 }} />}>
            <Confetti particleCount={100} spread={250} decay={0.75} gravity={0.8}>
              <AnimatedSuccessIcon size={64} />
            </Confetti>
          </Suspense>
        </Status.Icon>
        <Status.Title>
          <FormattedMessage
            id="billing.payments-free-trial-flow.confirmation.title"
            defaultMessage="Success! Your one month free trial starts today"
          />
        </Status.Title>
        <Status.Description>
          <FormattedMessage
            id="billing.payments-free-trial-flow.confirmation.description"
            defaultMessage="Your company can make unlimited payments for {days} days. Once your trial ends continue making payments on your current plan or upgrade."
            values={{ days }}
          />
        </Status.Description>
        <Status.Action variant="common" onClick={() => onDone()}>
          <FormattedMessage id="common.acknowledge" defaultMessage="Got it" />
        </Status.Action>
      </Status.Root>
    </Surface>
  )
}
