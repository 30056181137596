import type { Company } from 'api/types/companies'
import { useSendInteraction } from 'domains/analytics/hooks'
import { ImpossibleError } from 'kitchen/utils/error'
import { assert } from 'kitchen/utils/helpers'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Toast } from 'salad/components'
import type { PaymentStats } from '../../../payment/types'
import { useStartPaymentsFreeTrial } from '../../queries'
import type { CompanySubscription } from '../../types'
import { PaymentsFreeTrialConfirmation } from './payments-free-trial-confirmation'
import { PaymentsFreeTrialOverview } from './payments-free-trial-overview'
import { PaymentsFreeTrialVideo } from './payments-free-trial-video'

type PaymentsFreeTrialFlowType =
  | { step: 'video' }
  | { step: 'overview'; subscription: CompanySubscription }
  | { step: 'confirmation'; subscription: CompanySubscription }

interface PaymentsFreeTrialFlowProps {
  initialStep: PaymentsFreeTrialFlowType['step']
  company: Company
  paymentStats: PaymentStats
  onExit: () => void
}

export function PaymentsFreeTrialFlow({
  company,
  paymentStats,
  initialStep,
  onExit,
}: PaymentsFreeTrialFlowProps) {
  const sendInteraction = useSendInteraction(company.id)

  const [flow, setFlow] = useState<PaymentsFreeTrialFlowType>(() => {
    switch (initialStep) {
      case 'video':
        return { step: initialStep }
      case 'confirmation':
      case 'overview':
        assert(company.subscription)
        return { step: initialStep, subscription: company.subscription }
    }
  })

  const toast = Toast.useContext()
  const startPaymentsFreeTrial = useStartPaymentsFreeTrial({
    onError: () =>
      toast.show(
        <Toast.Root variant="error">
          <Toast.Title>
            <FormattedMessage
              id="billing.payments-free-trial-flow.errors.start-trial"
              defaultMessage="Failed to start trial"
            />
          </Toast.Title>
        </Toast.Root>
      ),
  })

  switch (flow.step) {
    case 'video':
      return (
        <PaymentsFreeTrialVideo
          company={company}
          paymentStats={paymentStats}
          onMore={(subscription) => {
            setFlow({
              step: 'overview',
              subscription,
            })

            sendInteraction({
              type: 'click',
              target: 'learn-more-payments-free-trial',
            })
          }}
          onConfirm={async (subscription) => {
            await startPaymentsFreeTrial.mutateAsync({
              subscriptionId: subscription.id,
            })

            setFlow({
              step: 'confirmation',
              subscription,
            })

            sendInteraction({
              type: 'click',
              target: 'start-payments-free-trial',
            })
          }}
          confirming={startPaymentsFreeTrial.isLoading}
        />
      )
    case 'overview':
      return (
        <PaymentsFreeTrialOverview
          company={company}
          onConfirm={async () => {
            await startPaymentsFreeTrial.mutateAsync({
              subscriptionId: flow.subscription.id,
            })

            setFlow({
              step: 'confirmation',
              subscription: flow.subscription,
            })

            sendInteraction({
              type: 'click',
              target: 'start-payments-free-trial',
            })
          }}
          confirming={startPaymentsFreeTrial.isLoading}
        />
      )
    case 'confirmation':
      return (
        <PaymentsFreeTrialConfirmation
          subscription={flow.subscription}
          onDone={() => onExit()}
        />
      )
    default:
      throw new ImpossibleError('Unhandled payments free trial flow step', flow)
  }
}
